<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'
import axios from '@/axios'
import Pagination from '@src/components/base/pagination'
import Search from '@src/components/base/search'
import moment from 'moment'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Category Order',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Pagination, Search },
  data() {
    return {
      show: false,
      modaldata: [],
      baseImageUrl: BASE_IMAGE_URL,
      title: 'Customer',
      search_types: [
        // { key: 'order_no', value: 'Order Number' },
        { key: 'email', value: 'Email' },
      ],
      sortByType: [
        // { key: 'Free', value: true },
        // { key: 'Member', value: false },
        { key: 'Free', value: 'Free' },
        { key: 'Active', value: 'Active' },
        { key: 'Inactive', value: 'Inactive' },
      ],

      sortByList: [
        { key: 'iOS', value: 'iOS' },
        { key: 'Android', value: 'Android' },
      ],
      search: {
        isActive: null,
        trial: null,
      },
      page: 1,
      data: [],
      pagination: {},
      sort_by: '',
      sort_type: '',
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    onSearch(search) {
      this.search = search
      this.page = 1
      this.onLoad()
    },
    async onLoad() {
      await axios
        .get('/api/order/getSubscriptionOrder', {
          params: {
            size: 20,
            page: this.page,
            ...this.search,
            platform: this.sort_by,
            orderByField: 'createdAt',
            orderBy: 'desc',
          },
        })
        .then((res) => {
          this.data = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },

    async openModal(data) {
      this.show = true
      this.modaldata = data

      // this.modalVisible = true
    },
    renderDate(date) {
      const formatDate = new Date(date)
      return moment(formatDate, 'MM/DD/YYYY').format('Do MMM YYYY')
    },
    changeName(name) {
      if (name === 'subs_news_content') {
        return 'News & Content'
      } else if (name === 'sub_exclusive_training') {
        return 'Exclusive Training'
      } else if (name === 'sub_premium_members') {
        return 'Premium Member'
      } else {
        return name
      }
    },
    async queryData(data) {
      if (data === 'Free') {
        this.search.trial = true
        this.search.isActive = false
      } else if (data === 'Active') {
        this.search.trial = false
        this.search.isActive = true
      } else if (data === 'Inactive') {
        this.search.trial = false
        this.search.isActive = false
      } else {
        this.search.trial = ''
        this.search.isActive = ''
      }
      this.onLoad()
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Order Category List</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <Search :types="search_types" @onSearch="onSearch($event)">
              <div class="input-group" style="margin-right:5px">
                <div class="input-group-prepend">
                  <label class="input-group-text">Platform:</label>
                </div>
                <select
                  class="custom-select"
                  v-model="sort_by"
                  @change="onLoad()"
                >
                  <option value>All</option>
                  <option
                    v-for="x in sortByList"
                    :key="x.key"
                    :value="x.value"
                    >{{ x.key }}</option
                  >
                </select>
              </div>
              <div class="input-group" style="margin-right:5px">
                <div class="input-group-prepend">
                  <label class="input-group-text">Payment Status:</label>
                </div>
                <select
                  class="custom-select"
                  v-model="sort_type"
                  @change="queryData(sort_type)"
                >
                  <!-- @change="onLoad()" -->
                  <option value>All</option>
                  <option
                    v-for="x in sortByType"
                    :key="x.key"
                    :value="x.value"
                    >{{ x.key }}</option
                  >
                </select>
              </div>
            </Search>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Order Name</th>
                    <th scope="col">Email</th>

                    <th scope="col">Customer name</th>
                    <th scope="col">City</th>

                    <th scope="col">Platform</th>
                    <th scope="col"></th>
                    <th scope="col" style="text-align: center;"
                      >Payment status</th
                    >
                    <th scope="col">expire date</th>

                    <!-- <th scope="col">Transaction status</th> -->
                    <th scope="col">CreatedAt</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td scope="row">{{
                      page == 1
                        ? page - 1 + index + 1
                        : (page - 1) * 20 + (index + 1)
                    }}</td>
                    <td scope="row">{{ changeName(item.iapProductId) }}</td>

                    <td scope="row">{{ item.user ? item.user.email : '' }}</td>
                    <td>
                      {{
                        item.user
                          ? item.user.firstName + ' ' + item.user.lastName
                          : null
                      }}</td
                    >
                    <td>{{
                      item.user
                        ? item.user.cityName
                          ? item.user.cityName + ',' + item.user.countryCode
                          : null
                        : null
                    }}</td>

                    <td scope="row">{{ item.platform }}</td>

                    <td scope="row"></td>
                    <td scope="row" style="text-align: center;">
                      {{
                        item.trial && !item.isActive
                          ? 'free'
                          : item.isActive
                          ? 'active'
                          : 'inactive'
                      }}</td
                    >
                    <td scope="row">
                      {{
                        item.acceptNewsAndContent
                          ? renderDate(item.acceptNewsAndContent.isExpiredDate)
                          : ''
                      }}
                    </td>

                    <!-- <td scope="row">{{ item.processorResponseText }}</td> -->
                    <td scope="row">{{ renderDate(item.createdAt) }}</td>
                    <!-- <td scope="row">   -->
                    <!-- <router-link :to="`/invoice/${item._id}`">
                        <button class="btn btn-info p-0 px-2 py-1 mr-2"
                          >View</button
                        >
                      </router-link></td
                    >  -->
                  </tr></tbody
                >
              </table>
            </div>
            <Pagination
              :data="pagination"
              :page="page"
              @onPage="onPage($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
